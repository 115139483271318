import {
  IDataSourceFactory,
  DataSourceFactoryInitializeArgs,
} from "@foxglove/studio-base/context/PlayerSelectionContext";
import { IterablePlayer, WorkerIterableSource } from "@foxglove/studio-base/players/IterablePlayer";
import { Player } from "@foxglove/studio-base/players/types";

class CowaRemoteRecordDataSourceFactory implements IDataSourceFactory {
  public id = "cowa-remote-record-file";
  public type: IDataSourceFactory["type"] = "remote-file";
  public displayName = "Cowa Record File";
  public iconName: IDataSourceFactory["iconName"] = "FileASPX";
  public supportedFileTypes = [".record"];
  public description = "Fetch and load pre-recorded Cowa .record files from a remote location.";
  public docsLink = "https://zi9wcyo1i5.feishu.cn/wiki/KlW1wDl26icIdFk3Gv0cg9YmndN"; //Promise-Foxglove数据规范

  public initialize(args: DataSourceFactoryInitializeArgs): Player | undefined {
    const url = args.params?.url;
    if (!url) {
      return;
    }

    console.log("record url", url);

    const source = new WorkerIterableSource({
      sourceType: "cowa-record",
      initArgs: { url },
    });

    return new IterablePlayer({
      metricsCollector: args.metricsCollector,
      source,
      name: url,
      sourceId: this.id,
      urlParams: {
        url,
      },
    });
  }
}

export default CowaRemoteRecordDataSourceFactory;
